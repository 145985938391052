import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion'
import { MDBCard, MDBCol, MDBContainer, MDBRow } from 'mdbreact'

import BreadcrumbMenu from '../../../components/menus/centerbcmenu'
import CClistScript from '../../../components/centers/closercenters/cclistscript'
import Carousel from '../../../components/centers/carouselcenter'
import CloserCenters from '../../../components/centers/closercenters/cclist'
import Events from '../../../components/happenings/events'
import InlineBodyTransform from '../../../components/inlinebodytransform'
import Layout from '../../../components/layout'
import MiddleCenter from '../../../components/centers/middlecenter'
import News from '../../../components/happenings/news'
import FinancialStatement from '../../../components/financialstatement/financialstatement'
import Ratings from '../../../components/centers/ratings/ratings'
import React from 'react'
import RightBox from '../../../components/menus/right_box'
import SectionContentSA from '../../../components/centers/servicesamenities'
import SeoCenters from '../../../components/centers/seocenters'
import StaffSection from '../../../components/centers/staffsection'
import TopCenter from '../../../components/centers/topcenter'
import Updates from '../../../components/updates/updates'
import Videobri from '../../../components/centers/videobri'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

let gmdv = require(`../../../ghc_config`)

const Detail = ({ data, location }) => {
  const post = data.nodeCenter
  const HappeningsEvents = data.allNodeHappenings
  const HappeningsNews = data.allNodeCentersHappeningsNews
  const linkedItems = data.linkedItems
  const allPracticemaxRatings = data.allPracticemaxRatings
  const allPracticemaxComments = data.allPracticemaxComments

  //  Start Position for closer Centers
  let startPosition = {}
  if (post.field_latitude && post.field_longitude) {
    startPosition = {
      lat: Number(post.field_latitude),
      lng: Number(post.field_longitude),
    }
  }

  /*  google destination https://developers.google.com/maps/documentation/urls/guide */
  let address = `${post.field_address_fl.trim()}, `
  address += `${post.field_city}, ${gmdv.selectState(post.field_state).spa} ${
    post.field_zip
  }`

  let mainphone =
    post.field_telephone_number == null ? '' : post.field_telephone_number
  let mainfax = post.field_fax_number == null ? '' : post.field_fax_number
  let admissionsphone =
    post.field_admissions_phone == null ? '' : post.field_admissions_phone
  let admissionsefax =
    post.field_admissions_efax == null ? '' : post.field_admissions_efax

  let destination = `${post.title}, ${address}`
  let dirGoogleLink = `https://www.google.com/maps/dir/?api=1`
  dirGoogleLink += `&destination=${encodeURI(destination)}`
  if (post.field_google_place_id) {
    dirGoogleLink += `&destination_place_id=${post.field_google_place_id}`
  }

  const financialstatementlistInactive = {
    // arborglen: [
    //   'Arbor_Glen_Medicare_cost_report_2021-12-31.pdf',
    //   'Arbor_Glen_Financial_Stmts_12-31-21.pdf',
    //   'Arbor_Glen_Medicare_cost_report_2022-12-31.pdf',
    //   'Arbor_Glen_Financial_Stmts_12-31-22.pdf',
    // ],
    // cranbury: [
    //   'Cranbury_Medicare_cost_report_2021-12-31.pdf',
    //   'Cranbury_Financial_Stmts_12-31-21.pdf',
    //   'Cranbury_Medicare_cost_report_2022-12-31.pdf',
    //   'Cranbury_Financial_Stmts_12-31-22.pdf',
    // ],
    // hollymanor: [
    //   'Holly_Manor_Medicare_cost_report_2021-12-31.pdf',
    //   'Holly_Manor_Financial_Stmts_12-31-21.pdf',
    //   'Holly_Manor_Medicare_cost_report_2022-12-31.pdf',
    //   'Holly_Manor_Financial_Stmts_12-31-22.pdf',
    // ],
    // lopatcong: [
    //   'Lopatcong_Medicare_cost_report_2021-12-31.pdf',
    //   'Lopatcong_Financial_Stmts_12-31-21.pdf',
    //   'Lopatcong_Medicare_cost_report_2022-12-31.pdf',
    //   'Lopatcong_Financial_Stmts_12-31-22.pdf',
    // ],
    // jerseyshore: [
    //   'Jersey_Shore_Medicare_cost_report_2021-12-31.pdf',
    //   'Jersey_Shore_Financial_Stmts_12-31-21.pdf',
    //   'Jersey_Shore_Medicare_cost_report_2022-12-31.pdf',
    //   'Jersey_Shore_Financial_Stmts_12-31-22.pdf',
    // ],
    // northcape: [
    //   'North_Cape_Medicare_cost_report_2021-12-31.pdf',
    //   'North_Cape_Financial_Stmts_12-31-21.pdf',
    //   'North_Cape_Medicare_cost_report_2022-12-31.pdf',
    //   'North_Cape_Financial_Stmts_12-31-22.pdf',
    // ],
    // mapleglen: [
    //   'Maple_Glen_Medicare_cost_report_2021-12-31.pdf',
    //   'Maple_Glen_Financial_Stmts_12-31-21.pdf',
    //   'Maple_Glen_Medicare_cost_report_2022-12-31.pdf',
    //   'Maple_Glen_Financial_Stmts_12-31-22.pdf',
    // ],
    // millville: [
    //   'Millville_Medicare_cost_report_2021-12-31.pdf',
    //   'Millville_Financial_Stmts_12-31-21.pdf',
    //   'Millville_Medicare_cost_report_2022-12-31.pdf',
    //   'Millville_Financial_Stmts_12-31-22.pdf',
    // ],
    // ridgewoodnj: [
    //   'Ridgewood_Medicare_cost_report_2021-12-31.pdf',
    //   'Ridgewood_Financial_Stmts_12-31-21.pdf',
    //   'Ridgewood_Medicare_cost_report_2022-12-31.pdf',
    //   'Ridgewood_Financial_Stmts_12-31-22.pdf',
    // ],
    // southernocean: [
    //   'Southern_Ocean_Medicare_cost_report_2021-12-31.pdf',
    //   'Southern_Ocean_Financial_Stmts_12-31-21.pdf',
    //   'Southern_Ocean_Medicare_cost_report_2022-12-31.pdf',
    //   'Southern_Ocean_Financial_Stmts_12-31-22.pdf',
    // ],
    // troyhills: [
    //   'Troy_Hills_Medicare_cost_report_2021-12-31.pdf',
    //   'Troy_Hills_Financial_Stmts_12-31-21.pdf',
    //   'Troy_Hills_Medicare_cost_report_2022-12-31.pdf',
    //   'Troy_Hills_Financial_Stmts_12-31-22.pdf',
    // ],
  }
  const financialstatementlist = {
    arborglen: [
      'Arbor_Glen_Financial_Stmts_2023-12-31.pdf',
      'Arbor_Glen_Medicare_cost_report_2023-12-31.pdf',
    ],
    cranbury: [
      'Cranbury_Financial_Stmts_2023-12-31.pdf',
      'Cranbury_Medicare_cost_report_2023-12-31.pdf',
    ],
    hollymanor: [
      'Holly_Manor_Financial_Stmts_2023-12-31.pdf',
      'Holly_Manor_Medicare_cost_report_2023-12-31.pdf',
    ],
    jerseyshore: [
      'Jersey_Shore_Financial_Stmts_2023-12-31.pdf',
      'Jersey_Shore_Medicare_cost_report_2023-12-31.pdf',
    ],
    lopatcong: [
      'Lopatcong_Financial_Stmts_2023-12-31.pdf',
      'Lopatcong_Medicare_cost_report_2023-12-31.pdf',
    ],

    mapleglen: [
      'Maple_Glen_Financial_Stmts_2023-12-31.pdf',
      'Maple_Glen_Medicare_cost_report_2023-12-31.pdf',
    ],
    millville: [
      'Millville_Financial_Stmts_2023-12-31.pdf',
      'Millville_Medicare_cost_report_2023-12-31.pdf',
    ],
    northcape: [
      'North_Cape_Financial_Stmts_2023-12-31.pdf',
      'North_Cape_Medicare_cost_report_2023-12-31.pdf',
    ],
    ridgewoodnj: [
      'Ridgewood_Financial_Stmts_2023-12-31.pdf',
      'Ridgewood_Medicare_cost_report_2023-12-31.pdf',
    ],
    southernocean: [
      'Southern_Ocean_Financial_Stmts_2023-12-31.pdf',
      'Southern_Ocean_Medicare_cost_report_2023-12-31.pdf',
    ],
    troyhills: [
      'Troy_Hills_Financial_Stmts_2023-12-31.pdf',
      'Troy_Hills_Medicare_cost_report_2023-12-31.pdf',
    ],
  }
  const disableChatPathAliasList = [
    'cheyennemountain',
    'cheyenneplace',
    'mesamanor',
    'pikespeak',
    'pueblo',
  ]
  const disableChatPathList = disableChatPathAliasList.map((i) => '/' + i)
  const disableChatPath = disableChatPathList.includes(post.path.alias)
  return (
    <>
      <Layout fb_id={post.field_facebook_id}>
        {disableChatPath ? null : (
          <Helmet>
            <script
              key="1"
              id="path-widget-tag"
              async
              data-company="p1005"
              type="text/javascript"
              src="https://ep.chatpath.com/widget.js"
            />
          </Helmet>
        )}
        <SeoCenters post={post} />
        <main>
          <MDBContainer tag="section" id="maincontent">
            <MDBRow center>
              <MDBCol xs="12" md="9" lg="9" className="col-12 contentLeft">
                <TopCenter
                  post={post}
                  location={location}
                  allPracticemaxRatings={allPracticemaxRatings}
                  address={address}
                  mainphone={mainphone}
                  mainfax={mainfax}
                  admissionsefax={admissionsefax}
                  admissionsphone={admissionsphone}
                />
                <CClistScript
                  startPosition={startPosition}
                  allPracticemaxRatings={allPracticemaxRatings}
                />
                <BreadcrumbMenu title={post.title} />
                <MiddleCenter post={post} dirGoogleLink={dirGoogleLink} />
                {post.relationships.field_image_carousel[0] ? (
                  <MDBCard className="p-2 border">
                    <Carousel images={post.relationships} />
                  </MDBCard>
                ) : (
                  ''
                )}
                <Accordion
                  allowZeroExpanded={true}
                  className="lightgray-bk-Genesis py-1 rounded"
                >
                  {post.field_bri ? <Videobri source={post.field_bri} /> : ''}
                  {(post.relationships.field_services &&
                    (post.relationships.field_services[0].id !==
                      `d055c173-114a-517c-8a44-f6cd28dd8f44` ||
                      post.relationships.field_services[0].name.toLowerCase() !==
                        `no service`)) ||
                  (post.relationships.field_amenities &&
                    (post.relationships.field_amenities[0].id !==
                      `55ded130-d505-5aef-9e5f-e00c7ede7cd7` ||
                      post.relationships.field_amenities[0].name.toLowerCase() !==
                        `no amenity`)) ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>{'Service & Amenities'}</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <SectionContentSA
                          services={post.relationships.field_services}
                          amenities={post.relationships.field_amenities}
                          linkedItems={linkedItems.edges}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ''
                  )}
                  <StaffSection paragraph={post} />
                  {HappeningsEvents.edges.length > 0 ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>Events</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <Events
                          EventList={HappeningsEvents}
                          name={post.title}
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ''
                  )}
                  {HappeningsNews.edges.length > 0 ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>News</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <News EventList={HappeningsNews} name={post.title} />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ''
                  )}
                  {financialstatementlist[post.path.alias.replace('/', '')] !==
                  undefined ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>Center Financial Statement</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <FinancialStatement
                          financialStatementUrls={
                            financialstatementlist[
                              post.path.alias.replace('/', '')
                            ]
                          }
                          // financialStatementInactiveUrls={
                          //   financialstatementlistInactive[
                          //     post.path.alias.replace('/', '')
                          //   ]
                          // }
                        />
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ''
                  )}
                  {post.field_outcomes ? (
                    <AccordionItem>
                      <AccordionItemHeading>
                        <AccordionItemButton>
                          <span>Outcomes</span>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <MDBCard className="bg-white px-2 py-2 m-0">
                          <InlineBodyTransform
                            bodyValue={post.field_outcomes}
                          />
                        </MDBCard>
                      </AccordionItemPanel>
                    </AccordionItem>
                  ) : (
                    ''
                  )}
                  <AccordionItem>
                    <Ratings
                      center={location.pathname}
                      allPracticemaxRatings={allPracticemaxRatings}
                      allPracticemaxComments={allPracticemaxComments}
                    />
                  </AccordionItem>
                </Accordion>

                {/** Lori requested to keep this code */}
                {/* <MDBRow className="my-3 mx-auto">
                  <MDBCol
                    xs="12"
                    sm="12"
                    md="12"
                    lg="12"
                    className="col-12 p-0 h-100 my-auto"
                  >
                    <Updates />
                  </MDBCol>
                </MDBRow> */}
              </MDBCol>
              <MDBCol xs="12" md="3" lg="3" className="col-12 rightbox">
                <RightBox />
                {Object.keys(startPosition).length > 0 && (
                  <CloserCenters
                    startPosition={startPosition}
                    allPracticemaxRatings={allPracticemaxRatings}
                  />
                )}
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </main>
      </Layout>
    </>
  )
}

export const query = graphql`
  query(
    $id: String!
    $alias: String!
    $pAlias: String!
    $today: Date!
    $tyago: Date!
  ) {
    nodeCenter(id: { eq: $id }) {
      id
      title
      field_address_fl
      field_city
      field_state
      field_zip
      field_telephone_number
      field_fax_number
      body {
        value
      }
      created
      field_outcomes {
        value
      }
      path {
        alias
      }
      field_google_place_id
      field_facebook_id
      field_latitude
      field_longitude
      field_bri
      field_vaccinated_staff
      field_vaccinated_residents
      field_admissions_efax
      field_admissions_phone
      #field_visitation_override_exp(formatString: "MM/DD/YYYY", locale: "en-US")
      #field_test_frequency_overide_exp(formatString: "MM/DD/YYYY", locale: "en-US")
      relationships {
        field_image_carousel {
          localFile {
            name
            ino
            id
            childImageSharp {
              #carousel: resize(width: 800, height:533, cropFocus: CENTER) {
              #  src
              #}
              carousel: gatsbyImageData(
                layout: CONSTRAINED
                width: 800
                height: 533
                transformOptions: { cropFocus: CENTER }
              )
              lb: original {
                src
              }
            }
          }
        }
        #field_virtual {
        #  field_pitch
        #  field_vaov
        #  field_minhfov
        #  field_maxhfov
        #  field_minhfov
        #  field_maxhfov
        #  field_minpitch
        #  field_maxpitch
        #  relationships {
        #    field_panorama {
        #      localFile {
        #        childImageSharp {
        #          panorama: original {
        #            src
        #          }
        #          tablet: resize(width: 4096) {
        #            src
        #          }
        #        }
        #      }
        #    }
        #  }
        #}
        field_staff_member {
          field_name
          field_sub_title
          relationships {
            field_member_photo {
              localFile {
                childImageSharp {
                  member_photo: resize(width: 100, height: 120) {
                    src
                  }
                }
              }
            }
          }
          field_description {
            value
          }
        }
        field_services {
          id
          name
          field_featured
          field_service_page_link
          path {
            alias
          }
        }
        field_amenities {
          id
          name
          path {
            alias
          }
        }
        field_awards_and_affiliations {
          id
          name
          field_service_page_link
          relationships {
            field_logo_image {
              localFile {
                childImageSharp {
                  resize(width: 90) {
                    src
                  }
                }
              }
            }
          }
        }
        field_visitation_status {
          name
          field_service_page_link
          relationships {
            field_badge_image {
              localFile {
                publicURL
              }
            }
          }
        }
        #field_visitation_status_override {
        #  name
        #  field_service_page_link
        #  relationships {
        #    field_badge_image {
        #      localFile {
        #        publicURL
        #      }
        #    }
        #  }
        #}
        field_testing_frequency_status {
          name
          field_service_page_link
          relationships {
            field_badge_image {
              localFile {
                publicURL
              }
            }
          }
        }
        #field_test_freq_status_override {
        #  name
        #  field_service_page_link
        #  relationships {
        #    field_badge_image {
        #      localFile {
        #        publicURL
        #      }
        #    }
        #  }
        #}
      }
    }
    # Events
    allNodeHappenings(
      filter: {
        relationships: { field_center: { path: { alias: { eq: $alias } } } }
        field_happening_s_date: { gte: $today }
      }
      sort: { fields: field_happening_s_date, order: DESC }
    ) {
      edges {
        node {
          field_happening_s_date
          title
          path {
            alias
          }
          relationships {
            field_center {
              title
            }
          }
        }
      }
    }
    # News
    allNodeCentersHappeningsNews(
      filter: {
        relationships: { field_center: { path: { alias: { eq: $alias } } } }
        field_happening_s_date: { gte: $tyago }
      }
      sort: { fields: field_happening_s_date, order: DESC }
    ) {
      edges {
        node {
          field_happening_s_date
          title
          path {
            alias
          }
          relationships {
            field_center {
              title
            }
          }
        }
      }
    }
    linkedItems: allTaxonomyTermServices(
      filter: { field_service_page_link: { ne: null } }
      sort: { fields: name }
    ) {
      edges {
        node {
          id
          name
          field_service_page_link
        }
      }
    }

    #centerStarsRatings
    allPracticemaxRatings {
      totalCount
      edges {
        node {
          shortName
          id
          medicalOrganizationEntity {
            name
            aggregateRating {
              _type
              bestRating
              ratingCount
              ratingValue
              worstRating
            }
          }
        }
      }
    }

    #Comments
    allPracticemaxComments(filter: { internal: { content: { eq: $pAlias } } }) {
      totalCount
      edges {
        node {
          ratingValue
          reviewDate
          authorType
          reviews {
            question
            answer
          }
          internal {
            content
          }
        }
      }
    }
  }
`

export default Detail
