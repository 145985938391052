import { MDBCol, MDBRow } from 'mdbreact'

import React from 'react'
import Stars from './ratings/centerstars'
import styled from 'styled-components'

import goodFaithEstimateImage from './images/GoodFaithEstimateImage.png'

const Img = styled.img`
  max-width: 3.5em;
`

const TopCenter = (props) => {
  const post = props.post
  const awards = post.relationships.field_awards_and_affiliations
  const allPracticemaxRatings = props.allPracticemaxRatings

  //  Separate the two kind of Awards
  let cmsAwards = []
  let othAwards = []
  if (awards != null) {
    awards.map((award) => {
      if (award.name.includes('CMS ')) {
        //uncomment this to include CMS awards
        // cmsAwards.push(award)
      } else {
        othAwards.push(award)
      }
    })
  }

  let numCmsAwards
  cmsAwards.length < 1 || cmsAwards == undefined
    ? (numCmsAwards = 0)
    : (numCmsAwards = getRowsArray(cmsAwards.length))
  let numOthAwards
  othAwards.length < 1 || othAwards == undefined
    ? (numOthAwards = 0)
    : (numOthAwards = getRowsArray(othAwards.length))

  let displayValue = `d-block`
  let displayTitle = `d-block`
  let titleSpace = `7`
  if (numCmsAwards === 0 && numOthAwards === 0) {
    displayTitle = `d-none`
  }
  if (
    numCmsAwards === 0 &&
    numOthAwards === 0 &&
    false && //always has the visitation status now //!post.relationships.field_visitation_status &&
    true //removed the testing frequency status //!post.relationships.field_testing_frequency_status
  ) {
    displayValue = `d-none`
    titleSpace = `12`
  }
  let logosSpaceLg = parseInt(titleSpace) - 1

  function getRowsArray(numRows) {
    let colValue
    colValue = `3`
    if (numRows === 1) {
      colValue = `12`
    }
    if (numRows === 2) {
      colValue = `6`
    }
    if (numRows % 3 === 0) {
      colValue = `4`
    }
    if (numRows % 4 === 0) {
      colValue = `3`
    }

    return colValue
  }

  return (
    <>
      <MDBRow className="my-0 mx-auto">
        <MDBCol
          xs="12"
          sm="12"
          md={titleSpace}
          lg={titleSpace}
          className="col-12 p-0 h-100 my-auto"
        >
          <h1 className="pagetitleCenters">{post.title}</h1>
          <Stars
            center={props.location.pathname.toLowerCase()}
            starsdata={props.starsdata}
            allPracticemaxRatings={allPracticemaxRatings}
            extValues={true}
          />
          <p>{props.address}</p>
          <p>
            Main Phone Number: {props.mainphone}
            {props.mainfax == '' ? (
              ''
            ) : (
              <>
                <br />
                Main Fax Number: {props.mainfax}
              </>
            )}
            {props.admissionsphone == '' ? (
              ''
            ) : (
              <>
                <br />
                Admissions Phone Number: {props.admissionsphone}
              </>
            )}
            {props.admissionsefax == '' ? (
              ''
            ) : (
              <>
                <br />
                Admissions e-Fax: {props.admissionsefax}
              </>
            )}
          </p>
        </MDBCol>
        <MDBCol
          xs="12"
          sm="12"
          md="4"
          lg="5"
          className={`col-12 mx-auto p-2 ${displayValue}`}
        >
          <h6 className={`w-100 text-center ${displayTitle}`}>
            Awards and Affiliations
          </h6>
          <MDBRow center>
            {cmsAwards.map((award, aKey) => {
              let logoAward = ``
              {
                award.relationships.field_logo_image
                  ? (logoAward =
                      award.relationships.field_logo_image.localFile
                        .childImageSharp.resize.src)
                  : ``
              }
              return (
                <MDBCol
                  key={aKey}
                  xs={numCmsAwards}
                  sm={numCmsAwards}
                  md={numCmsAwards == `3` ? '6' : numCmsAwards}
                  lg={numCmsAwards}
                  className={`col-${numCmsAwards} my-1 text-center`}
                >
                  <a href={award.field_service_page_link} target="_blank">
                    <Img src={logoAward} alt={award.name} title={award.name} />
                  </a>
                </MDBCol>
              )
            })}
          </MDBRow>
          <MDBRow center>
            {othAwards.map((award, aKey) => {
              let logoAward = ``
              {
                award.relationships.field_logo_image
                  ? (logoAward =
                      award.relationships.field_logo_image.localFile
                        .childImageSharp.resize.src)
                  : ``
              }
              return (
                <MDBCol
                  key={aKey}
                  xs={numOthAwards}
                  sm={numOthAwards}
                  md={numOthAwards == `3` ? '6' : numOthAwards}
                  lg={numOthAwards}
                  className={`col-${numOthAwards} my-1 text-center`}
                >
                  <a href={award.field_service_page_link} target="_blank">
                    <Img src={logoAward} alt={award.name} title={award.name} />
                  </a>
                </MDBCol>
              )
            })}
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </>
  )
}

export default TopCenter
